<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink :destination="'/'" :vegangLogo="true"> </buttonLink>
            <button-link
              :isInternal="true"
              v-if="loggedIn && userType == 'retailer'"
              :content="' Go To Retailer Dashboard'"
              :destination="'/retailer'"
              :filled="true"
            >
            </button-link>
            <button-link
              :isInternal="true"
              v-if="!loggedIn"
              :content="'Login as Retailer'"
              :destination="'/login/retailer'"
              :outlined="true"
            >
            </button-link>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-page for-supplier">
      <div class="authpage-header">
        <h1 class="title for-supplier">
          Sign up As a <span class="green-text">Supplier</span>
          <span class="sub for-supplier"
            >Selling more through thousands of retailers for Free</span
          >
        </h1>
      </div>

      <div class="auth-box">
        <h2 class="form-title">Create Your VGANG Account</h2>
        <div class="errorMessage-container" v-if="showAuthError">
          <div class="material-icons error-icon">cancel</div>
          <div>
            Ops! something happened
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-body">
          <div class="form">
            <CustomInput
              type="email"
              id="email"
              icon="email"
              label="Email address"
              v-model="register.email"
              :required="true"
            />
            <CustomPasswordInput
              type="password"
              id="password"
              icon="key"
              label="Password"
              v-model="register.password"
            />
            <button class="vgang-button form-button" @click="handleRegister">
              Signup with email
            </button>
          </div>
        </div>
        <div class="box-footer">
          <span class="footer-text"
            >Already have a Supplier Account?
            <buttonLink
              :content="' Sign in'"
              :destination="'/login/supplier'"
              :greenText="true"
            ></buttonLink>
          </span>
        </div>
      </div>
      <img
        src="@/assets/img/supplier--bg.svg"
        loading="lazy"
        alt="auth"
        class="auth-shape"
      />
    </div>
  </main>
</template>

<script>
// Components
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";

// Models
import User from "@/models/user";

// Services
import { uuid } from "vue-uuid";
import CommonData from "@/services/CommonData";
import buttonLink from "@/components/bases/ButtonLink.vue";
import { mapGetters } from "vuex";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";
export default {
  metaInfo: {
    title: "vgang|register-supplier",
    meta: [
      { name: "description", content: "Do you want to be a supplier?click me" },
    ],
  },
  data() {
    return {
      userType: "",
      showAuthError: false,
      errorMessage: "",
      user: new User("", ""),
      uuid: uuid.v1(),
      register: {
        email: "",
        password: "",
        DeviceId: this.$uuid.v4(),
        userType: "supplier",
        service: "vgang",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    buttonLink,
  },
  computed: {
    ...mapGetters(["getGlobalHost"]),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.checkRole();
    }
  },
  methods: {
    checkRole() {
      const user = getVariable("user");
      if (user) {
        CommonData.getCurrentUser()
          .then(function (response) {
            this.userType = response.data.role;
            setVariable("role", this.userType);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return;
      }
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      this.$store.dispatch("auth/register", this.register).then(
        (data) => {
          // console.log(data);
          this.successful = true;
          this.$router.push({ name: "Supplier" });
          this.showAuthError = false;
        },
        (error) => {
          this.successful = false;
          this.showAuthError = true;
          this.errorMessage = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style></style>
